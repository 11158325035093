import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { router } from '../Router';
import { Copyright } from '../components';
import { useDispatch, useSelector } from '../hooks/hooks';
import Logo from '../logo';
import { RootState } from '../redux/configureStore';
import { AdminUser } from '../redux/features/adminUserSlice';
import { setLoggedIn } from '../redux/features/configSlice';
import { CurrentAdminUserState, setCurrentAdminUser } from '../redux/features/currentAdminUserSlice';
import { adminUserFetches } from '../service';
import { tokens } from '../theme';

export function Login() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const loggedIn = useSelector((state: RootState) => state.configReducer.loggedIn);

	// server and validation errors
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const loginAdminUserInitialValues: AdminUser = {
		email: '',
		password: '',
	};

	React.useEffect(() => {
		if (loggedIn === true) {
			history.push(router.home().$);
		}
	}, [history]);

	// handle authentication
	const handleAuthenticateFormSubmit = async (values: AdminUser) => {
		const adminUserToLogin = {
			...values,
			email: values.email.trim(),
		};
		// execute fetch request to backend
		const adminUserData = await adminUserFetches.authenticateFetch(adminUserToLogin);
		if (adminUserData.status === true) {
			// get token from response and save it in local storage
			const token = adminUserData.data.token;
			localStorage.setItem('token', token);

			// set current admin user data in store
			const currentAdminUser: CurrentAdminUserState = {
				id: adminUserData.data.id,
				email: adminUserData.data.email.trim(),
				role: adminUserData.data.role,
			};
			await dispatch(setCurrentAdminUser(currentAdminUser));

			// login status
			await dispatch(setLoggedIn(true));

			// go to home page
			history.push(router.home().$);
		} else {
			// set backend error message for UI view
			setServerErrorMessage(adminUserData.error.value);
		}
	};

	const loginAdminUserValidationSchema = yup.object().shape({
		email: yup.string().trim().email('invalid email').required('required'),
		password: yup.string().min(4, 'must be at least 4 characters long').required('required'),
	});

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100%"
			sx={{ gridColumn: 'span 4' }}
		>
			<Avatar sx={{ bgcolor: '#FF377F', mt: 'auto' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography variant="h3" mb="30px" mt="10px">
				Sign in
			</Typography>
			<Logo width={undefined} style={{ marginTop: '8px' }} />
			<Formik
				onSubmit={async (submitProps) => {
					try {
						await handleAuthenticateFormSubmit(submitProps);
					} catch (err: any) {
						setServerErrorMessage('Failed to connect to server!');
					}
				}}
				initialValues={loginAdminUserInitialValues}
				validationSchema={loginAdminUserValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(1,300px)"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								name="email"
								error={!!touched.email && !!errors.email}
								helperText={touched.email && errors.email}
								sx={{ gridColumn: 'span 3' }}
							/>

							<TextField
								fullWidth
								variant="filled"
								type="password"
								label="Password"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.password}
								name="password"
								error={!!touched.password && !!errors.password}
								helperText={touched.password && errors.password}
								sx={{ gridColumn: 'span 3' }}
							/>
						</Box>
						<Box
							display="flex"
							mt="40px"
							mb="20px"
							gap="20px"
							flexDirection="column"
							justifyContent="center"
						>
							<Button type="submit" color="secondary" variant="contained">
								Log In
							</Button>
							<Typography variant="h6" sx={{ color: colors.redAccent[500], alignSelf: 'center' }}>
								{serverErrorMessage}
							</Typography>
						</Box>
					</form>
				)}
			</Formik>
			<Copyright />
		</Box>
	);
}
