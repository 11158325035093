import { Stack, Typography } from '@mui/material';
import React from 'react';

import { useHistory } from 'react-router-dom';
import { Configuration } from '../config/Config';

export const ErrorPage = () => {
	const history = useHistory();

	React.useEffect(() => {
		const checkServerStatus = async () => {
			try {
				const response = await fetch(`${Configuration.baseUrl}/api/internal/health/check`);
				if (response.status === 200) {
					// Server is back online, go back
					history.goBack();
				} else {
					// Server is still down, schedule another check after a delay
					setTimeout(checkServerStatus, 5000); // Check again after 5 seconds
				}
			} catch (error) {
				// Server is still down, schedule another check after a delay
				setTimeout(checkServerStatus, 5000); // Check again after 5 seconds
			}
		};
		checkServerStatus();
	}, []);

	return (
		<div className="Content">
			<Stack
				gap={4}
				py={{ xs: 10, md: 20 }}
				px={1}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Stack gap={3} alignItems={'center'}>
					<Typography variant="h3" color={'secondary'}>
						Server Error!
					</Typography>
					<Typography variant="h5" textAlign={'center'}>
						We're sorry, but an error ocurred. This could be due to maintenance, server not working, a
						temporary overload, or a technical issue.
					</Typography>
					<Typography variant="h4" color={'secondary'}>
						What You Can Do:
					</Typography>
					<Typography variant="h5">
						<ul>
							<li>Please try again later.</li>
							<li>Check your internet connection to ensure it's stable.</li>
							<li>Contact support if the problem persists.</li>
						</ul>
					</Typography>
				</Stack>
			</Stack>
		</div>
	);
};

export default ErrorPage;
