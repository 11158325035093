import { BlockchainInfo } from '../../redux/features/configSlice';
import { Configuration } from '../Config';

export const getBlockchainsInfoArray = () => {
	return [
		Configuration.maticBlockchain,
		Configuration.ethereumBlockchain,
		Configuration.binanceBlockchain,
		Configuration.binanceTestnetBlockchain,
		Configuration.baseBlockchain,
		Configuration.blastTestnetBlockchain,
		Configuration.arbitrumBlockchain,
	] as BlockchainInfo[];
};
