import { Autocomplete, Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../components/global/Header';
import { Configuration } from '../../config/Config';
import { useDispatch } from '../../hooks/hooks';
import { BlogPost, add } from '../../redux/features/blogPostSlice';
import { logout } from '../../redux/features/configSlice';

import { router } from '../../Router';
import { blogPostFetches } from '../../service';
import { tokens } from '../../theme';

export function AddBlogPostPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const dispatch = useDispatch();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [selectedFile, setSelectedFile] = React.useState<{ photoFile: File; error: string }>();
	const blogPostInitialValues: BlogPost = {
		title: '',
		description: '',
		category: '',
		blogLink: '',
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
	}, [history]);

	const handleAddFormSubmit = async (values: BlogPost) => {
		if (selectedFile?.photoFile) {
			const formData = new FormData();
			formData.append('file', selectedFile!.photoFile as File);
			formData.append('blogPost', JSON.stringify(values));

			// execute add request and add blog post to redux store
			const addResponse = await blogPostFetches.addFetch(formData);

			if (addResponse.status === 403) {
				await dispatch(logout());
				return;
			}
			const data = await addResponse.json();

			if (data.status === true) {
				// get created blog post from response
				const blogPostToStore = {
					...data.data,
					photoUrl: data.data.photoUrl,
				} as BlogPost;

				// set created blog post data to redux store
				dispatch(add(blogPostToStore));

				history.push(router.listBlogPosts().$);
			} else {
				setServerErrorMessage(data.error.value);
			}
		} else {
			setSelectedFile({ ...selectedFile!, error: 'Photo is not set!' });
		}
	};

	const blogPostValidationSchema = yup.object().shape({
		title: yup
			.string()
			.min(4, 'must be at least 4 characters long')
			.max(50, 'must not exceed 50 characters')
			.required('required'),
		description: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		category: yup.string().min(4, 'must be at least 4 characters long').required('required'),
		blogLink: yup.string().url('Must be a valid URL').required('required'),
		isFeatured: yup.boolean().optional(),
	});

	return (
		<Box m="20px">
			<Header title="ADD BLOG POST" subtitle="Create a New Blog Post" />
			<Formik
				onSubmit={handleAddFormSubmit}
				initialValues={blogPostInitialValues}
				validationSchema={blogPostValidationSchema}
			>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Box
							mt="40px"
							display="grid"
							gap="30px"
							gridTemplateColumns="repeat(4,minmax(0,1fr))"
							sx={{
								'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
							}}
						>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Title"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.title}
								name="title"
								error={!!touched.title && !!errors.title}
								helperText={touched.title && errors.title}
								sx={{ gridColumn: 'span 2' }}
							/>
							<Autocomplete
								options={Configuration.blogCategory}
								disableClearable
								selectOnFocus
								clearOnBlur
								onChange={(e, value) => setFieldValue('category', value)}
								autoHighlight
								sx={{ gridColumn: 'span 2' }}
								getOptionLabel={(option) => option}
								// value={values.category}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="filled"
										type="text"
										onBlur={handleBlur}
										label="Category"
										name="category"
										error={!!touched.category && !!errors.category}
										helperText={touched.category && errors.category}
									/>
								)}
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Description"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.description}
								name="description"
								error={!!touched.description && !!errors.description}
								helperText={touched.description && errors.description}
								sx={{ gridColumn: 'span 4' }}
								multiline
							/>
							<TextField
								fullWidth
								variant="filled"
								type="text"
								label="Blog Link"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.blogLink}
								name="blogLink"
								error={!!touched.blogLink && !!errors.blogLink}
								helperText={touched.blogLink && errors.blogLink}
								sx={{ gridColumn: 'span 4' }}
							/>
							<Box mb="12px" display="flex" gap="10px" sx={{ gridColumn: 'span 4' }}>
								<Typography variant="h5" color={colors.primary[100]}>
									Set Blog Post Photo
								</Typography>
								<input
									type="file"
									style={{ color: colors.redAccent[500] }}
									onChange={(e) =>
										setSelectedFile({ ...selectedFile!, photoFile: e.target.files![0] })
									}
								/>
								<Typography
									variant="h6"
									sx={{ color: colors.redAccent[500], alignSelf: 'center', width: '45%' }}
								>
									{selectedFile?.error ? `-${selectedFile?.error}-` : null}
								</Typography>
							</Box>
						</Box>
						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color="secondary" variant="contained">
								Create New Blog Post
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
}
