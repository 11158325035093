import CheckIcon from '@mui/icons-material/Check';
import UncheckIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardMedia,
	Divider,
	FormControlLabel,
	Switch,
	Tab,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from 'react-typesafe-routes';
import * as yup from 'yup';
import { router } from '../../Router';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { Loader } from '../../components/Loader';
import Header from '../../components/global/Header';
import { useDispatch, useSelector } from '../../hooks/hooks';
import { RootState } from '../../redux/configureStore';
import { logout } from '../../redux/features/configSlice';
import {
	FeaturedProject,
	FeaturedProjectData,
	TeamMember,
	deleteCoverPhoto,
	deleteLogoPhoto,
	makePublic,
	update,
	updateCoverPhoto,
	updateLogoPhoto,
} from '../../redux/features/featuredProjectSlice';
import { featuredProjectFetches } from '../../service';
import { tokens } from '../../theme';
import { ListFundingRoundsPage } from '../fundingRounds/ListFundingRoundsPage';

export function GetFeaturedProjectPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	// get id from route params
	const { id } = useRouteParams(router.getFeaturedProject);

	// set server and validation errors
	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	// get selected fetured project from redux store
	const selectedFeaturedProject = useSelector(
		(state: RootState) => state.featuredProjectReducer.selectedFeaturedProject
	);

	// set tab navigation and loading status
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedTab, setSelectedTab] = React.useState('1');

	// set if featured project is visible and if is presentation purpose
	const [checkVisible, setCheckVisible] = React.useState({ checked: selectedFeaturedProject?.isVisible });
	const [checkPresentation, setCheckPresentation] = React.useState({
		checked: selectedFeaturedProject?.isPresentation,
	});

	const [selectedFile, setSelectedFile] = React.useState<{ coverFile: File; logoFile: File }>();

	// desctructuring data for form initial values
	const { teamMembers, ...featuredProject } = { ...selectedFeaturedProject };

	const [formDataInitialValues, setFormDataInitialValues] = React.useState<FeaturedProjectData>({
		featuredProject: {
			...featuredProject,
		} as FeaturedProject,

		teamMembers: teamMembers as TeamMember[],
	});

	React.useEffect(() => {
		// verify if logged in
		if (localStorage.length === 0) {
			history.push(router.login().$);
		}
		if (selectedFeaturedProject) {
			const { teamMembers: updatedTeamMembers, ...updatedFeaturedProject } = { ...selectedFeaturedProject };
			setCheckVisible({ checked: selectedFeaturedProject.isVisible });
			setCheckPresentation({ checked: selectedFeaturedProject.isPresentation });
			setFormDataInitialValues({
				featuredProject: {
					...updatedFeaturedProject,
				} as FeaturedProject,

				teamMembers: updatedTeamMembers as TeamMember[],
			});
		}
	}, [history, selectedFeaturedProject, selectedTab]);

	// handle data update
	const handleUpdateFormSubmit = async (values: FeaturedProjectData) => {
		const featuredProjectDataToBackend = {
			...values,
			featuredProject: {
				...values.featuredProject,
				isPresentation: checkPresentation.checked,
				isVisible: checkVisible.checked,
				coverUrl: selectedFeaturedProject?.coverUrl,
				logoUrl: selectedFeaturedProject?.logoUrl,
			},
		} as FeaturedProjectData;

		const currentFundingRoundDataToSaveToStore = values.featuredProject.currentFundingRound;

		const updateResponse = await featuredProjectFetches.updateFetch(featuredProjectDataToBackend);

		if (updateResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			// get updated featured project from response
			let featuredProjectToAddToStore = data.data;
			featuredProjectToAddToStore.currentFundingRound = currentFundingRoundDataToSaveToStore;

			// set updated featured project and team members data to redux store
			dispatch(update(featuredProjectToAddToStore));

			history.push(router.listFeaturedProjects().$);
			// history.push(router.getFeaturedProject({ id: id }).$);
		} else {
			setServerErrorMessage(data.error.value);
		}
	};

	// handle updating featured project to public for view in client UI
	const handleMakePublic = async () => {
		if (validateMakePublic(selectedFeaturedProject!)) {
			const publicResponse = await featuredProjectFetches.makePublicFetch(id!);

			if (publicResponse.status === 403) {
				await dispatch(logout());
				return;
			}
			const data = await publicResponse.json();

			if (data.status === true) {
				dispatch(makePublic(id));
				setCheckVisible({ checked: true });
				history.push(router.listFeaturedProjects().$);
			} else {
				setServerErrorMessage(data.error.value);
			}
		} else {
			setIsLoading(false);
		}
	};

	const handleDeletePhoto = async (urlType: string) => {
		if (selectedFeaturedProject?.id) {
			let featuredProjectPhotoToDelete = { id: id } as FeaturedProject;

			if (urlType === 'coverUrl') {
				featuredProjectPhotoToDelete.coverUrl = selectedFeaturedProject.coverUrl;
			}
			if (urlType === 'logoUrl') {
				featuredProjectPhotoToDelete.logoUrl = selectedFeaturedProject.logoUrl;
			}

			setIsLoading(true);
			const removePhotoResponse = await featuredProjectFetches.removePhotoFetch(
				featuredProjectPhotoToDelete,
				urlType
			);

			if (removePhotoResponse.status === 403) {
				await dispatch(logout());
				return;
			}
			const data = await removePhotoResponse.json();

			if (data.status === true) {
				if (urlType === 'coverUrl') {
					dispatch(deleteCoverPhoto(selectedFeaturedProject.id));
				}
				if (urlType === 'logoUrl') {
					dispatch(deleteLogoPhoto(selectedFeaturedProject.id));
				}
				setIsLoading(false);
				if (isLoading === false) {
					// history.push(router.listFeaturedProjects().$);
					history.push(router.getFeaturedProject({ id: id }).$);
				}
			} else {
				setServerErrorMessage(data.error.value);
				setIsLoading(false);
			}
		}
	};

	const handleUploadPhoto = async (urlType: string) => {
		let file: File;
		if (urlType === 'coverUrl') {
			file = selectedFile!.coverFile;
		}
		if (urlType === 'logoUrl') {
			file = selectedFile!.logoFile;
		}
		const formData = new FormData();
		formData.append('file', file!);
		formData.append('urlType', urlType);

		if (selectedFeaturedProject?.id) {
			formData.append('id', selectedFeaturedProject.id.toString());
		}

		setIsLoading(true);
		const updatePhotoResponse = await featuredProjectFetches.updatePhotoFetch(formData);

		if (updatePhotoResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const data = await updatePhotoResponse.json();

		if (data.status === true) {
			if (urlType === 'coverUrl') {
				const featuredProjectPhotoUpdate = { id: id, coverUrl: data.data } as FeaturedProject;
				dispatch(updateCoverPhoto(featuredProjectPhotoUpdate));
			}
			if (urlType === 'logoUrl') {
				const featuredProjectPhotoUpdate = { id: id, logoUrl: data.data } as FeaturedProject;
				dispatch(updateLogoPhoto(featuredProjectPhotoUpdate));
			}

			setIsLoading(false);
			if (isLoading === false) {
				//history.push(router.listFeaturedProjects().$);
				history.push(router.getFeaturedProject({ id: id }).$);
			}
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	const handleCheckVisibleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckVisible({ ...checkVisible, [event.target.name]: event.target.checked });
	};

	const handleCheckPresentationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckPresentation({ ...checkPresentation, [event.target.name]: event.target.checked });
	};

	// validanting data to make featured project public
	const validateMakePublic = (values: FeaturedProject) => {
		const errors = {
			coverUrlError: false,
			logoUrlError: false,
			currentFundingRoundError: false,
			signUpStartDateError: false,
			signUpDeadLineError: false,
		};

		if (!values.coverUrl) {
			errors.coverUrlError = true;
		}
		if (!values.logoUrl) {
			errors.logoUrlError = true;
		}
		if (!values.currentFundingRoundId) {
			errors.currentFundingRoundError = true;
		}
		if (!values.currentFundingRound?.signUpStartDate) {
			errors.signUpStartDateError = true;
		}
		if (!values.currentFundingRound?.signUpDeadline) {
			errors.signUpDeadLineError = true;
		}

		// set errors
		if (
			errors.coverUrlError ||
			errors.logoUrlError ||
			errors.currentFundingRoundError ||
			errors.signUpStartDateError ||
			errors.signUpDeadLineError
		) {
			setServerErrorMessage('Criteria for public visible have not been met!');
			return false;
		}
		return true;
	};

	// formik validation schema
	const formDataValidationSchema = yup.object().shape({
		featuredProject: yup.object().shape({
			name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
			projectOverview: yup
				.string()
				.min(4, 'must be at least 4 characters long')
				.max(255, 'maximum characters 255')
				.required('required'),
			status: yup.string().required('required'),
			isVisible: yup.boolean().required('required'),
			isPresentation: yup.boolean().required('required'),
			coinSymbol: yup.string().optional(),
			websiteUrl: yup.string().url('Must be a valid URL').optional(),
			twitterUrl: yup.string().url('Must be a valid URL').optional(),
			discordUrl: yup.string().url('Must be a valid URL').optional(),
			youtubeUrl: yup.string().url('Must be a valid URL').optional(),
			telegramURl: yup.string().url('Must be a valid URL').optional(),
			mediumUrl: yup.string().url('Must be a valid URL').optional(),
			type: yup.string().optional(),
			urlName: yup
				.string()
				.matches(/^[a-zA-Z0-9]+$/, 'String must contain only letters and numbers')
				.required('required'),
			showOffUrl: yup.string().url('Must be a valid URL').optional(),
		}),
		teamMembers: yup.array().of(
			yup.object().shape({
				name: yup.string().min(4, 'must be at least 4 characters long').required('required'),
				position: yup.string().min(3, 'must be at least 3 characters long').required('required'),
				description: yup
					.string()
					.min(4, 'must be at least 4 characters long')
					.max(255, 'maximum characters 255')
					.required('required'),
				//photoUrl: yup.string().url('Must be a valid URL').optional(),
				linkedInUrl: yup.string().url('Must be a valid URL').optional(),
				twitterUrl: yup.string().url('Must be a valid URL').optional(),
				telegramUrl: yup.string().url('Must be a valid URL').optional(),
			})
		),
	});

	const addNewTeamMember = (values: any, setFieldValue: any) => {
		setFieldValue('teamMembers', [
			...values.teamMembers,
			{ name: '', position: '', description: '', photoUrl: '', linkedInUrl: '', twitterUrl: '', telegramUrl: '' },
		]);
	};

	const makePublicConfirmation = () => {
		// Open the dialog
		setIsOpen(true);
	};

	const handleConfirm = () => {
		// Implement the logic to make the project public
		handleMakePublic();
		// Close the dialog
		setIsOpen(false);
	};

	const handleCancel = () => {
		// Close the dialog without making the project public
		setIsOpen(false);
	};

	return selectedFeaturedProject ? (
		<Box>
			{isLoading ? <Loader open={isLoading} /> : null}
			<TabContext value={selectedTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
						<Tab label="Info" value="1" />
						<Tab label="Funding Rounds" value="2" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<Box>
						<Box sx={{ gridColumn: 'span 4' }} display="flex" justifyItems="flex-start" gap="10px">
							<Header
								title="FEATURED PROJECT INFO"
								subtitle="Featured Project data / Update Featured Project"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={checkVisible.checked}
										onChange={handleCheckVisibleChange}
										name="checked"
										color="success"
										disabled={selectedFeaturedProject.isVisible ? false : true}
									/>
								}
								label=""
							/>
							<Typography variant="h4" sx={{ alignSelf: 'center' }}>
								Visible
							</Typography>
							{selectedFeaturedProject?.isVisible ? (
								<CheckIcon sx={{ color: 'green', alignSelf: 'center' }} fontSize="large" />
							) : (
								<UncheckIcon sx={{ color: 'red', alignSelf: 'center' }} fontSize="large" />
							)}

							<Typography variant="h6" sx={{ color: 'red', alignSelf: 'center', width: '45%' }}>
								{serverErrorMessage && <span> -{serverErrorMessage}- </span>}
							</Typography>
							<FormControlLabel
								control={
									<Switch
										checked={checkPresentation.checked}
										onChange={handleCheckPresentationChange}
										name="checked"
										color="success"
									/>
								}
								sx={{ alignSelf: 'flex-start', justifySelf: 'end', ml: 'auto' }}
								label="Presentation"
							/>
						</Box>
						<Divider sx={{ gridColumn: 'span 4', mt: '30px' }} />
						<Box mt="40px" mb="40px" gap="30%">
							<Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
								Criteria for Public Visible
							</Typography>
							<Box mt="20px" display="flex" flexDirection="column">
								<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
									Current Funding Round:
									{selectedFeaturedProject.currentFundingRoundId ? (
										<CheckIcon sx={{ color: 'green' }} fontSize="large" />
									) : (
										<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
									)}
								</Typography>
								<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
									Curent Funding Round Sign up start date:{' '}
									{selectedFeaturedProject.currentFundingRound?.signUpStartDate ? (
										<CheckIcon sx={{ color: 'green' }} fontSize="large" />
									) : (
										<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
									)}
								</Typography>
								<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
									Curent Funding Round Sign up deadline date:{' '}
									{selectedFeaturedProject.currentFundingRound?.signUpDeadline ? (
										<CheckIcon sx={{ color: 'green' }} fontSize="large" />
									) : (
										<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
									)}
								</Typography>
								<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
									Cover URL:{' '}
									{selectedFeaturedProject.coverUrl ? (
										<CheckIcon sx={{ color: 'green' }} fontSize="large" />
									) : (
										<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
									)}
								</Typography>
								<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
									Logo URL:{' '}
									{selectedFeaturedProject.logoUrl ? (
										<CheckIcon sx={{ color: 'green' }} fontSize="large" />
									) : (
										<UncheckIcon sx={{ color: 'red' }} fontSize="large" />
									)}
								</Typography>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									onClick={makePublicConfirmation}
									sx={{ alignSelf: 'start', mt: '20px' }}
									disabled={selectedFeaturedProject?.isVisible ? true : false}
								>
									Make Public
								</Button>
								<ConfirmationDialog
									open={isOpen}
									onClose={handleCancel}
									onConfirm={handleConfirm}
									content="Are you sure you want to make this project public?"
								/>
							</Box>
						</Box>
						<Divider sx={{ gridColumn: 'span 4' }} />
						<Box mt="40px" display="flex" justifyContent="center" gap="30%">
							<Box>
								<Box mb="12px">
									<input
										type="file"
										style={{ color: colors.redAccent[500] }}
										onChange={(e) =>
											setSelectedFile({ ...selectedFile!, coverFile: e.target.files![0] })
										}
									/>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleUploadPhoto('coverUrl')}
										sx={{ color: colors.grey[100] }}
										disabled={selectedFile?.coverFile ? false : true}
									>
										Upload Cover
									</Button>
								</Box>
								<Box>
									{selectedFeaturedProject.coverUrl !== null &&
									selectedFeaturedProject.coverUrl !== '' &&
									selectedFeaturedProject.coverUrl !== undefined ? (
										<Card sx={{ maxWidth: '345' }}>
											<CardMedia
												component="img"
												image={`${selectedFeaturedProject.coverUrl}`}
												height="200"
												title="Media"
											/>
											<CardActions disableSpacing>
												<Button
													sx={{
														color: colors.redAccent[500],
														':hover': {
															bgcolor: colors.blueAccent[200],
															color: colors.redAccent[800],
														},
													}}
													onClick={() => handleDeletePhoto('coverUrl')}
												>
													Remove Cover
												</Button>
											</CardActions>
										</Card>
									) : null}
								</Box>
							</Box>
							<Box>
								<Box mb="12px">
									<input
										type="file"
										style={{ color: colors.redAccent[500] }}
										onChange={(e) =>
											setSelectedFile({ ...selectedFile!, logoFile: e.target.files![0] })
										}
									/>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => handleUploadPhoto('logoUrl')}
										sx={{ color: colors.grey[100] }}
										disabled={selectedFile?.logoFile ? false : true}
									>
										Upload Logo
									</Button>
								</Box>
								<Box>
									{selectedFeaturedProject.logoUrl !== null &&
									selectedFeaturedProject.logoUrl !== '' &&
									selectedFeaturedProject.logoUrl !== undefined ? (
										<Card sx={{ maxWidth: '345' }}>
											<CardMedia
												image={`${selectedFeaturedProject.logoUrl}`}
												title="Media"
												height="200"
												component="img"
											/>
											<CardActions disableSpacing>
												<Button
													sx={{
														color: colors.redAccent[500],
														':hover': {
															bgcolor: colors.blueAccent[200],
															color: colors.redAccent[800],
														},
													}}
													onClick={() => handleDeletePhoto('logoUrl')}
												>
													Remove Logo
												</Button>
											</CardActions>
										</Card>
									) : null}
								</Box>
							</Box>
						</Box>
						<Formik
							onSubmit={handleUpdateFormSubmit}
							initialValues={formDataInitialValues}
							validationSchema={formDataValidationSchema}
						>
							{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
								<form onSubmit={handleSubmit}>
									{/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
									<Box
										mt="40px"
										display="grid"
										gap="30px"
										gridTemplateColumns="repeat(4,minmax(0,1fr))"
										sx={{
											'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
										}}
									>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Featured Project Name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.name}
											name="featuredProject.name"
											error={!!touched.featuredProject?.name && !!errors.featuredProject?.name}
											helperText={touched.featuredProject?.name && errors.featuredProject?.name}
											sx={{ gridColumn: 'span 2' }}
											inputProps={{
												readOnly:
													selectedFeaturedProject.currentFundingRound
														?.fundingContractAddress ||
													selectedFeaturedProject.currentFundingRound?.vestingContractAddress
														? true
														: false,
											}}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Coin Symbol"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.coinSymbol}
											name="featuredProject.coinSymbol"
											error={
												!!touched.featuredProject?.coinSymbol &&
												!!errors.featuredProject?.coinSymbol
											}
											helperText={
												touched.featuredProject?.coinSymbol &&
												errors.featuredProject?.coinSymbol
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Project Overview"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.projectOverview}
											name="featuredProject.projectOverview"
											error={
												!!touched.featuredProject?.projectOverview &&
												!!errors.featuredProject?.projectOverview
											}
											helperText={
												touched.featuredProject?.projectOverview &&
												errors.featuredProject?.projectOverview
											}
											sx={{ gridColumn: 'span 4' }}
											multiline
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Url Name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.urlName}
											name="featuredProject.urlName"
											error={
												!!touched.featuredProject?.urlName && !!errors.featuredProject?.urlName
											}
											helperText={
												touched.featuredProject?.urlName && errors.featuredProject?.urlName
											}
											sx={{ gridColumn: 'span 2' }}
											multiline
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Type"
											onBlur={handleBlur}
											value={values.featuredProject.type}
											name="featuredProject.type"
											sx={{ gridColumn: 'span 2' }}
											inputProps={{
												readOnly: true,
											}}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Status"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.status}
											name="featuredProject.status"
											error={
												!!touched.featuredProject?.status && !!errors.featuredProject?.status
											}
											helperText={
												touched.featuredProject?.status && errors.featuredProject?.status
											}
											sx={{ gridColumn: 'span 2' }}
											inputProps={{ readOnly: true }}
										/>
										<Divider sx={{ gridColumn: 'span 4' }} />
										<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
											Social links
										</Typography>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Show Off Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.showOffUrl}
											name="featuredProject.showOffUrl"
											error={
												!!touched.featuredProject?.showOffUrl &&
												!!errors.featuredProject?.showOffUrl
											}
											helperText={
												touched.featuredProject?.showOffUrl &&
												errors.featuredProject?.showOffUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Website Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.websiteUrl}
											name="featuredProject.websiteUrl"
											error={
												!!touched.featuredProject?.websiteUrl &&
												!!errors.featuredProject?.websiteUrl
											}
											helperText={
												touched.featuredProject?.websiteUrl &&
												errors.featuredProject?.websiteUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Twitter Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.twitterUrl}
											name="featuredProject.twitterUrl"
											error={
												!!touched.featuredProject?.twitterUrl &&
												!!errors.featuredProject?.twitterUrl
											}
											helperText={
												touched.featuredProject?.twitterUrl &&
												errors.featuredProject?.twitterUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Discord Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.discordUrl}
											name="featuredProject.discordUrl"
											error={
												!!touched.featuredProject?.discordUrl &&
												!!errors.featuredProject?.discordUrl
											}
											helperText={
												touched.featuredProject?.discordUrl &&
												errors.featuredProject?.discordUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Youtube Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.youtubeUrl}
											name="featuredProject.youtubeUrl"
											error={
												!!touched.featuredProject?.youtubeUrl &&
												!!errors.featuredProject?.youtubeUrl
											}
											helperText={
												touched.featuredProject?.youtubeUrl &&
												errors.featuredProject?.youtubeUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Telegram Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.telegramURl}
											name="featuredProject.telegramURl"
											error={
												!!touched.featuredProject?.telegramURl &&
												!!errors.featuredProject?.telegramURl
											}
											helperText={
												touched.featuredProject?.telegramURl &&
												errors.featuredProject?.telegramURl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<TextField
											fullWidth
											variant="filled"
											type="text"
											label="Medium Url"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.featuredProject.mediumUrl}
											name="featuredProject.mediumUrl"
											error={
												!!touched.featuredProject?.mediumUrl &&
												!!errors.featuredProject?.mediumUrl
											}
											helperText={
												touched.featuredProject?.mediumUrl && errors.featuredProject?.mediumUrl
											}
											sx={{ gridColumn: 'span 2' }}
										/>
										<Divider sx={{ gridColumn: 'span 4' }} />
										<Box
											sx={{ gridColumn: 'span 4' }}
											display="flex"
											justifyItems="flex-start"
											gap="20px"
										>
											<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
												Team Members
											</Typography>
											<Button
												color="secondary"
												variant="contained"
												onClick={() => addNewTeamMember(values, setFieldValue)}
											>
												Add Team Member
											</Button>
										</Box>
										{values.teamMembers?.map((teamMember: TeamMember, index) => {
											return (
												<Box
													key={index}
													mt="5px"
													display="grid"
													gap="30px"
													gridTemplateColumns="repeat(4,minmax(0,1fr))"
													sx={{
														'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
													}}
												>
													<TextField
														label="Team Member Name"
														type="text"
														name={`teamMembers.${index}.name`}
														variant="filled"
														onBlur={handleBlur}
														onChange={handleChange}
														value={teamMember.name}
														error={
															!!(
																touched.teamMembers && touched.teamMembers[index]?.name
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).name
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.name &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).name
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<TextField
														label="Position"
														name={`teamMembers.${index}.position`}
														onChange={handleChange}
														onBlur={handleBlur}
														type="text"
														variant="filled"
														value={teamMember.position}
														error={
															!!(
																touched.teamMembers &&
																touched.teamMembers[index]?.position
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).position
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.position &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).position
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<TextField
														label="Description"
														name={`teamMembers.${index}.description`}
														onChange={handleChange}
														onBlur={handleBlur}
														type="text"
														variant="filled"
														value={teamMember.description}
														error={
															!!(
																touched.teamMembers &&
																touched.teamMembers[index]?.description
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).description
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.description &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).description
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<TextField
														label="LinkedIn Url"
														name={`teamMembers.${index}.linkedInUrl`}
														onChange={handleChange}
														onBlur={handleBlur}
														type="text"
														variant="filled"
														value={teamMember.linkedInUrl}
														error={
															!!(
																touched.teamMembers &&
																touched.teamMembers[index]?.linkedInUrl
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).linkedInUrl
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.linkedInUrl &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).linkedInUrl
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<TextField
														label="Twitter Url"
														name={`teamMembers.${index}.twitterUrl`}
														onChange={handleChange}
														onBlur={handleBlur}
														type="text"
														variant="filled"
														value={teamMember.twitterUrl}
														error={
															!!(
																touched.teamMembers &&
																touched.teamMembers[index]?.twitterUrl
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).twitterUrl
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.twitterUrl &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).twitterUrl
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<TextField
														label="Telegram Url"
														name={`teamMembers.${index}.telegramUrl`}
														onChange={handleChange}
														onBlur={handleBlur}
														type="text"
														variant="filled"
														value={teamMember.telegramUrl}
														error={
															!!(
																touched.teamMembers &&
																touched.teamMembers[index]?.telegramUrl
															) &&
															!!(errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).telegramUrl
																? true
																: false)
														}
														helperText={
															touched.teamMembers &&
															touched.teamMembers[index]?.telegramUrl &&
															errors.teamMembers &&
															errors.teamMembers[index] &&
															(errors.teamMembers[index] as TeamMember).telegramUrl
														}
														fullWidth
														sx={{ gridColumn: 'span 4' }}
														multiline
													/>
													<Divider sx={{ gridColumn: 'span 4' }} />
													{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
												</Box>
											);
										})}
									</Box>
									<Box display="flex" justifyContent="end" mt="20px">
										<Button type="submit" color="secondary" variant="contained">
											Update Featured Project
										</Button>
									</Box>
								</form>
							)}
						</Formik>
					</Box>
				</TabPanel>
				<TabPanel value="2">
					<ListFundingRoundsPage />
				</TabPanel>
			</TabContext>
		</Box>
	) : null;
}
