import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Tab, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { router } from '../Router';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { Loader } from '../components/Loader';
import Header from '../components/global/Header';
import { Configuration } from '../config/Config';
import { useDispatch } from '../hooks/hooks';
import { Marketplace, logout } from '../redux/features/configSlice';
import { marketplaceFetches } from '../service';
import { tokens } from '../theme';

interface MarketplaceForm {
	marketplace: Marketplace;
	newPlatformFee: string;
	newTreasuryAddress: string;
	newPayableToken: string;
}

export function MarketplacePage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	const [isOpen, setIsOpen] = React.useState(false);
	const [action, setAction] = React.useState(() => () => {}); // Default action, does nothing
	const [actionName, setActionName] = React.useState('');

	// tab for navigation
	const [selectedTab, setSelectedTab] = React.useState('1');
	const [selectedTabBlockchain, setSelectedTabBlockchain] = React.useState(Configuration.maticBlockchain.label);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [marketplace, setMarketplace] = React.useState<Marketplace>();
	const marketplaceInitialValues: MarketplaceForm = {
		marketplace: marketplace!,
		newPlatformFee: '',
		newTreasuryAddress: '',
		newPayableToken: '',
	};

	const handleRefresh = async () => {
		// refetch data from contract
		initMarketplace(Configuration.maticBlockchain.label);
	};

	const initMarketplace = async (blockchain: string) => {
		setIsLoading(true);
		const marketplaceResponse = await marketplaceFetches.getMarketplaceDataFetch(blockchain);

		if (marketplaceResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const marketplaceData = await marketplaceResponse.json();

		if (marketplaceData.status === true) {
			setMarketplace(marketplaceData.data);
			setIsLoading(false);
		} else {
			setServerErrorMessage(`${marketplaceData.error.value}`);
			setMarketplace(undefined);
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			setServerErrorMessage('');
			if (selectedTab === '1') {
				setMarketplace(undefined);
				initMarketplace(Configuration.maticBlockchain.label);
				setSelectedTabBlockchain(Configuration.maticBlockchain.label);
			}
			if (selectedTab === '2') {
				setMarketplace(undefined);
				initMarketplace(Configuration.ethereumBlockchain.label);
				setSelectedTabBlockchain(Configuration.ethereumBlockchain.label);
			}
			if (selectedTab === '3') {
				setMarketplace(undefined);
				initMarketplace(Configuration.binanceBlockchain.label);
				setSelectedTabBlockchain(Configuration.binanceBlockchain.label);
			}
			if (selectedTab === '4') {
				setMarketplace(undefined);
				initMarketplace(Configuration.binanceTestnetBlockchain.label);
				setSelectedTabBlockchain(Configuration.binanceTestnetBlockchain.label);
			}
			if (selectedTab === '5') {
				setMarketplace(undefined);
				initMarketplace(Configuration.baseBlockchain.label);
				setSelectedTabBlockchain(Configuration.baseBlockchain.label);
			}
			if (selectedTab === '6') {
				setMarketplace(undefined);
				initMarketplace(Configuration.blastTestnetBlockchain.label);
				setSelectedTabBlockchain(Configuration.blastTestnetBlockchain.label);
			}
			if (selectedTab === '7') {
				setMarketplace(undefined);
				initMarketplace(Configuration.arbitrumBlockchain.label);
				setSelectedTabBlockchain(Configuration.arbitrumBlockchain.label);
			}
		}
	}, [history, selectedTab]);

	// handle marketplace changes
	const handleChangePlatformFee = async (values: MarketplaceForm) => {
		setIsLoading(true);

		const marketplaceDataToBackend: any = {
			newPlatformFee: values.newPlatformFee,
			marketplace: values.marketplace.marketplaceAddress,
		};

		// update marketplace request
		const updateResponse = await marketplaceFetches.updateMarketplaceDataFetch(
			selectedTabBlockchain,
			marketplaceDataToBackend
		);

		if (updateResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			handleRefresh();
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleChangeTreasuryAddress = async (values: MarketplaceForm) => {
		setIsLoading(true);

		const marketplaceDataToBackend: any = {
			newTreasuryAddress: values.newTreasuryAddress,
			marketplace: values.marketplace.marketplaceAddress,
		};

		// update marketplace request
		const updateResponse = await marketplaceFetches.updateMarketplaceDataFetch(
			selectedTabBlockchain,
			marketplaceDataToBackend
		);

		if (updateResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			handleRefresh();
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	const handleAddPayableToken = async (values: MarketplaceForm) => {
		setIsLoading(true);

		const marketplaceDataToBackend: any = {
			newPayableToken: values.newPayableToken,
			marketplace: values.marketplace.marketplaceAddress,
		};

		// update marketplace request
		const updateResponse = await marketplaceFetches.updateMarketplaceDataFetch(
			selectedTabBlockchain,
			marketplaceDataToBackend
		);

		if (updateResponse.status === 403) {
			dispatch(logout());
			return;
		}
		const data = await updateResponse.json();

		if (data.status === true) {
			handleRefresh();
			setIsLoading(false);
		} else {
			setServerErrorMessage(data.error.value);
			setIsLoading(false);
		}
	};

	// handle tab navigation selection
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	const formDataValidationSchema = yup.object().shape({
		newPlatformFee: yup
			.string()
			.matches(/^[0-9]+$/, 'Min Allocation must contain only numbers')
			.optional(),
		newTreasuryAddress: yup
			.string()
			.optional()
			.test({
				name: 'addressError',
				message: 'invalid address',
				test: (value: any) => !value || ethers.utils.isAddress(value),
			}),
		newPayableToken: yup
			.string()
			.optional()
			.test({
				name: 'addressError',
				message: 'invalid address',
				test: (value: any) => !value || ethers.utils.isAddress(value),
			}),
	});

	const handleConfirmation = (confirmedAction: any, actionName: string) => {
		setAction(() => confirmedAction);
		setActionName(actionName);
		// Open the dialog
		setIsOpen(true);
	};

	const handleConfirm = () => {
		// Implement the logic to make the project public
		action(); // Perform the action associated with the button
		// Close the dialog
		setIsOpen(false);
	};

	const handleCancel = () => {
		// Close the dialog without making the project public
		setIsOpen(false);
	};

	const FormComponent = () => {
		return (
			<Box>
				{isLoading ? <Loader open={isLoading} /> : null}
				{marketplace ? (
					<Formik
						onSubmit={() => {}}
						initialValues={marketplaceInitialValues!}
						validationSchema={formDataValidationSchema}
					>
						{({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
							<form onSubmit={handleSubmit}>
								<Box
									mt="40px"
									display="grid"
									gap="30px"
									gridTemplateColumns="repeat(4,minmax(0,1fr))"
									sx={{
										'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
									}}
								>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Marketplace Address"
										onBlur={handleBlur}
										value={values.marketplace.marketplaceAddress}
										name="marketplace.marketplaceAddress"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>

									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Platform Fee"
										onBlur={handleBlur}
										value={values.marketplace.platformFee}
										name="marketplace.platformFee"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Treasury Address"
										onBlur={handleBlur}
										value={values.marketplace.treasuryAddress}
										name="marketplace.treasuryAddress"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Payable Tokens Address
									</Typography>
									{values.marketplace.payableTokens.map((entry: any, index) => {
										return (
											<TextField
												key={index}
												fullWidth
												variant="filled"
												type="text"
												label={`Payable Token ${index}`}
												onBlur={handleBlur}
												value={entry}
												name="payableToken"
												sx={{ gridColumn: 'span 4' }}
												inputProps={{ readOnly: true }}
											/>
										);
									})}
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Update Values
									</Typography>
									<ConfirmationDialog
										open={isOpen}
										onClose={handleCancel}
										onConfirm={handleConfirm}
										content={actionName}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="New Platform Fee"
										value={values.newPlatformFee}
										onChange={handleChange}
										onBlur={handleBlur}
										name="newPlatformFee"
										error={!!touched.newPlatformFee && !!errors.newPlatformFee}
										helperText={touched.newPlatformFee && errors.newPlatformFee}
										sx={{ gridColumn: 'span 3' }}
									/>
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											handleConfirmation(
												() => handleChangePlatformFee(values),
												'Are you sure you want to change the platform fee for marketplace contract?'
											)
										}
										size="large"
										sx={{ gridColumn: 'span 1' }}
										disabled={!values.newPlatformFee ? true : false}
									>
										Change platform fee
									</Button>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="New Treasury Address"
										value={values.newTreasuryAddress}
										onChange={handleChange}
										onBlur={handleBlur}
										name="newTreasuryAddress"
										error={!!touched.newTreasuryAddress && !!errors.newTreasuryAddress}
										helperText={touched.newTreasuryAddress && errors.newTreasuryAddress}
										sx={{ gridColumn: 'span 3' }}
									/>
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											handleConfirmation(
												() => handleChangeTreasuryAddress(values),
												'Are you sure you want to change the treasurry address for marketplace contract?'
											)
										}
										size="large"
										sx={{ gridColumn: 'span 1' }}
										disabled={!values.newTreasuryAddress ? true : false}
									>
										Change treasury address
									</Button>
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Payable Tokens
									</Typography>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="New Payable Token Address"
										value={values.newPayableToken}
										onChange={handleChange}
										onBlur={handleBlur}
										name="newPayableToken"
										error={!!touched.newPayableToken && !!errors.newPayableToken}
										helperText={touched.newPayableToken && errors.newPayableToken}
										sx={{ gridColumn: 'span 3' }}
									/>
									<Button
										variant="contained"
										color="secondary"
										onClick={() =>
											handleConfirmation(
												() => handleAddPayableToken(values),
												'Are you sure you want to add the payable token address for marketplace contract?'
											)
										}
										size="large"
										sx={{ gridColumn: 'span 1' }}
										disabled={!values.newPayableToken ? true : false}
									>
										Add payable token address
									</Button>
								</Box>
							</form>
						)}
					</Formik>
				) : null}
			</Box>
		);
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="MARKETPLACE" />
				<Typography
					variant="h6"
					sx={{ color: colors.redAccent[500], alignSelf: 'flex-start', width: '40%', ml: '10px', mt: '7px' }}
				>
					{serverErrorMessage ? `-There is a server error-${serverErrorMessage}` : null}
				</Typography>
			</Box>
			<Box>
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
							<Tab label="Matic" value="1" />
							<Tab label="Ethereum" value="2" />
							<Tab label="Binance" value="3" />
							<Tab label="Binance Testnet" value="4" />
							<Tab label="Base" value="5" />
							<Tab label="Blast Testnet" value="6" />
							<Tab label="Arbitrum" value="7" />
						</TabList>
					</Box>
					<TabPanel value="1">
						<FormComponent />
					</TabPanel>
					<TabPanel value="2">
						<FormComponent />
					</TabPanel>
					<TabPanel value="3">
						<FormComponent />
					</TabPanel>
					<TabPanel value="4">
						<FormComponent />
					</TabPanel>
					<TabPanel value="5">
						<FormComponent />
					</TabPanel>
					<TabPanel value="6">
						<FormComponent />
					</TabPanel>
					<TabPanel value="7">
						<FormComponent />
					</TabPanel>
				</TabContext>
			</Box>
		</Box>
	);
}
