import { Configuration } from '../config/Config';
import { FundingRoundData } from '../redux/features/fundingRoundSlice';
import { FundingRoundService } from './url';

export const fundingRoundFetches = {
	listFetch: async (featuredProjectId: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.LIST_FUNDING_ROUNDS}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				featuredProjectId,
			}),
		});
		return response;
	},

	addFetch: async (fundingRoundData: FundingRoundData) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.CREATE_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...fundingRoundData,
			}),
		});
		return response;
	},

	updateFetch: async (fundingRoundData: FundingRoundData) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.UPDATE_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				...fundingRoundData,
			}),
		});
		return response;
	},

	removeFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.REMOVE_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return response;
	},

	getFetch: async (fundingRoundId?: number) => {
		let getUrl = `${Configuration.baseUrl}${FundingRoundService.GET_FUNDING_ROUND}`;

		if (fundingRoundId !== undefined) {
			getUrl = `${getUrl}/${fundingRoundId}`;
		}

		const response = await fetch(getUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		});
		return response;
	},

	createCustodyLedgerContract: async (fundingRoundId: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.CREATE_CUSTODY_LEDGER_CONTRACT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				fundingRoundId,
			}),
		});
		return response;
	},

	createVestingTermsContract: async (fundingRoundId: number, vestingSchedule: string) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.CREATE_VESTING_TERMS_CONTRACT}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				fundingRoundId,
				vestingSchedule,
			}),
		});
		return response;
	},

	getContractsDataFundingRound: async (fundingRoundId: number) => {
		const response = await fetch(
			`${Configuration.baseUrl}${FundingRoundService.GET_CONTRACTS_DATA_FUNDING_ROUND}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accepts: 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					fundingRoundId,
				}),
			}
		);
		return response;
	},

	startFundingFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.START_FUNDING_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return response;
	},

	startVestingFetch: async (id: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.START_VESTING_FUNDING_ROUND}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				id,
			}),
		});
		return response;
	},

	addContractToMarketplaceFetch: async (fundingRoundId: number) => {
		const response = await fetch(`${Configuration.baseUrl}${FundingRoundService.ADD_CONTRACT_TO_MARKETPLACE}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accepts: 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
			body: JSON.stringify({
				fundingRoundId,
			}),
		});
		return response;
	},

	withdrawAllTokensFromVestingContract: async (fundingRoundId: number) => {
		const response = await fetch(
			`${Configuration.baseUrl}${FundingRoundService.WITHDRAW_ALL_TOKENS_FROM_VESTING_CONTRACT}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accepts: 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					fundingRoundId,
				}),
			}
		);
		return response;
	},

	withdrawRaisedStableCoinsFromFundingContract: async (fundingRoundId: number) => {
		const response = await fetch(
			`${Configuration.baseUrl}${FundingRoundService.WITHDRAW_RAISED_STABLE_COINS_FROM_FUNDING_CONTRACT}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accepts: 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				body: JSON.stringify({
					fundingRoundId,
				}),
			}
		);
		return response;
	},
};
