import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Tab, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { Loader } from '../components/Loader';
import Header from '../components/global/Header';
import { Configuration } from '../config/Config';
import { useDispatch } from '../hooks/hooks';
import { TreasuryWallet, logout } from '../redux/features/configSlice';
import { walletFetches } from '../service';
import { tokens } from '../theme';

export function TreasuryWalletPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');

	// tab for navigation
	const [selectedTab, setSelectedTab] = React.useState('1');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [treasuryWallet, setTreasuryWallet] = React.useState<TreasuryWallet>();

	const initTreasuryWallet = async (blockchainLabel: string) => {
		setIsLoading(true);
		const walletResponse = await walletFetches.getTreasuryWalletDataFetch(blockchainLabel);

		if (walletResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const walletData = await walletResponse.json();

		if (walletData.status === true) {
			setTreasuryWallet(walletData.data);
			setIsLoading(false);
		} else {
			setTreasuryWallet(undefined);
			setServerErrorMessage(`${walletData.error}`);
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			setServerErrorMessage('');
			if (selectedTab === '1') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.maticBlockchain.label);
			}
			if (selectedTab === '2') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.ethereumBlockchain.label);
			}
			if (selectedTab === '3') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.binanceBlockchain.label);
			}
			if (selectedTab === '4') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.binanceTestnetBlockchain.label);
			}
			if (selectedTab === '5') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.baseBlockchain.label);
			}
			if (selectedTab === '6') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.blastTestnetBlockchain.label);
			}
			if (selectedTab === '7') {
				setTreasuryWallet(undefined);
				initTreasuryWallet(Configuration.arbitrumBlockchain.label);
			}
		}
	}, [history, selectedTab]);

	// handle tab navigation selection
	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	const FormComponent = (props: any) => {
		const chainLabel = `${props.chain} Amount`;
		return (
			<Box>
				{isLoading ? <Loader open={isLoading} /> : null}
				{treasuryWallet ? (
					<Formik onSubmit={() => {}} initialValues={treasuryWallet}>
						{({ values, handleBlur }) => (
							<form>
								<Box
									mt="40px"
									display="grid"
									gap="30px"
									gridTemplateColumns="repeat(4,minmax(0,1fr))"
									sx={{
										'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
									}}
								>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Wallet Address"
										onBlur={handleBlur}
										value={values.treasuryWalletAddress}
										name="treasuryWalletAddress"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>

									<TextField
										fullWidth
										variant="filled"
										type="text"
										label={chainLabel}
										onBlur={handleBlur}
										value={values.treasuryWalletAmount}
										name="treasuryWalletAmount"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="USDC Amount"
										onBlur={handleBlur}
										value={values.USDCAmount}
										name="USDCAmount"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Supported Tokens
									</Typography>
									{values.supportedTokensAmounts.map((entry: any, index) => {
										return (
											<TextField
												key={index}
												fullWidth
												variant="filled"
												type="text"
												label={entry.name}
												onBlur={handleBlur}
												value={entry.amount}
												name="supportedTokensAmounts"
												sx={{ gridColumn: 'span 4' }}
												inputProps={{ readOnly: true }}
											/>
										);
									})}
								</Box>
							</form>
						)}
					</Formik>
				) : null}
			</Box>
		);
	};

	return (
		<Box m="20px">
			{isLoading ? <Loader open={isLoading} /> : null}
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="TREASURY WALLET" />
				<Typography
					variant="h6"
					sx={{ color: colors.redAccent[500], alignSelf: 'flex-start', width: '40%', ml: '10px', mt: '7px' }}
				>
					{serverErrorMessage ? `-There is a server error-${serverErrorMessage}` : null}
				</Typography>
			</Box>
			<Box>
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary">
							<Tab label="Matic" value="1" />
							<Tab label="Ethereum" value="2" />
							<Tab label="Binance" value="3" />
							<Tab label="Binance Testnet" value="4" />
							<Tab label="Base" value="5" />
							<Tab label="Blast Testnet" value="6" />
							<Tab label="Arbitrum" value="7" />
						</TabList>
					</Box>
					<TabPanel value="1">
						<FormComponent chain="Matic" />
					</TabPanel>
					<TabPanel value="2">
						<FormComponent chain="Ethereum" />
					</TabPanel>
					<TabPanel value="3">
						<FormComponent chain="Binance" />
					</TabPanel>
					<TabPanel value="4">
						<FormComponent chain="Binance Testnet" />
					</TabPanel>
					<TabPanel value="5">
						<FormComponent chain="Base" />
					</TabPanel>
					<TabPanel value="6">
						<FormComponent chain="Blast Testnet" />
					</TabPanel>
					<TabPanel value="7">
						<FormComponent chain="Arbitrum" />
					</TabPanel>
				</TabContext>
			</Box>
		</Box>
	);
}
