import { Box, Divider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { router } from '../Router';
import { Loader } from '../components/Loader';
import Header from '../components/global/Header';
import { useDispatch } from '../hooks/hooks';
import { CompanyWallet, logout } from '../redux/features/configSlice';
import { walletFetches } from '../service';
import { tokens } from '../theme';

export function CompanyWalletsPage() {
	const history = useHistory();
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const dispatch = useDispatch();

	const [serverErrorMessage, setServerErrorMessage] = React.useState('');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [companyWallets, setCompanyWallets] = React.useState<{
		adminWallet: CompanyWallet;
		feederWallet: CompanyWallet;
	}>();

	const initCompanyWallets = async () => {
		setIsLoading(true);
		const walletResponse = await walletFetches.getCompanyWalletsDataFetch();

		if (walletResponse.status === 403) {
			await dispatch(logout());
			return;
		}
		const walletData = await walletResponse.json();

		if (walletData.status === true) {
			setCompanyWallets(walletData.data);
			setIsLoading(false);
		} else {
			setServerErrorMessage(`${walletData.error}`);
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (localStorage.length === 0) {
			history.push(router.login().$);
		} else {
			setServerErrorMessage('');
			initCompanyWallets();
		}
	}, [history]);

	return (
		<Box m="20px">
			{isLoading ? <Loader open={isLoading} /> : null}
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="COMPANY WALLETS" />
				<Typography
					variant="h6"
					sx={{ color: colors.redAccent[500], alignSelf: 'flex-start', width: '40%', ml: '10px', mt: '7px' }}
				>
					{serverErrorMessage ? `-There is a server error-${serverErrorMessage}` : null}
				</Typography>
			</Box>
			<Box>
				{companyWallets ? (
					<Formik onSubmit={() => {}} initialValues={companyWallets}>
						{({ values, handleBlur }) => (
							<form>
								<Box
									mt="40px"
									display="grid"
									gap="30px"
									gridTemplateColumns="repeat(4,minmax(0,1fr))"
									sx={{
										'& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
									}}
								>
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Admin Wallet
									</Typography>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Wallet Address"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAddress}
										name="companyWalletAddress"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Matic Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountMatic}
										name="companyWalletAmountMatic"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Ethereum Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountEthereum}
										name="companyWalletAmountEthereum"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Binance Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountBinance}
										name="companyWalletAmountBinance"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Binance Testnet Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountBinanceTestnet}
										name="companyWalletAmountBinanceTestnet"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Base Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountBase}
										name="companyWalletAmountBase"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Blast Testnet Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountBlastTestnet}
										name="companyWalletAmountBlastTestnet"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Arbitrum Amount"
										onBlur={handleBlur}
										value={values.adminWallet.companyWalletAmountArbitrum}
										name="companyWalletAmountArbitrum"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<Divider sx={{ gridColumn: 'span 4' }} />
									<Typography variant="h4" sx={{ alignSelf: 'center', gridColumn: 'span 4' }}>
										Feeder Wallet
									</Typography>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Wallet Address"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAddress}
										name="companyWalletAddress"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Matic Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountMatic}
										name="companyWalletAmountMatic"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Ethereum Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountEthereum}
										name="companyWalletAmountEthereum"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Binance Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountBinance}
										name="companyWalletAmountBinance"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Binance Testnet Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountBinanceTestnet}
										name="companyWalletAmountBinanceTestnet"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Base Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountBase}
										name="companyWalletAmountBase"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Blast Testnet Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountBlastTestnet}
										name="companyWalletAmountBlastTestnet"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
									<TextField
										fullWidth
										variant="filled"
										type="text"
										label="Arbitrum Amount"
										onBlur={handleBlur}
										value={values.feederWallet.companyWalletAmountArbitrum}
										name="companyWalletAmountArbitrum"
										sx={{ gridColumn: 'span 4' }}
										inputProps={{ readOnly: true }}
									/>
								</Box>
							</form>
						)}
					</Formik>
				) : null}
			</Box>
		</Box>
	);
}
