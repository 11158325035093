export enum FundingRoundService {
	LIST_FUNDING_ROUNDS = '/api/v1/featured-projects/list-funding-rounds',
	CREATE_FUNDING_ROUND = '/api/v1/featured-projects/create-funding-round',
	UPDATE_FUNDING_ROUND = '/api/v1/featured-projects/update-funding-round',
	REMOVE_FUNDING_ROUND = '/api/v1/featured-projects/remove-funding-round',
	GET_FUNDING_ROUND = '/api/v1/featured-projects/get-funding-round',
	CREATE_CUSTODY_LEDGER_CONTRACT = '/api/v1/featured-projects/create-custody-ledger-contract',
	CREATE_VESTING_TERMS_CONTRACT = '/api/v1/featured-projects/create-vesting-terms-contract',
	GET_CONTRACTS_DATA_FUNDING_ROUND = '/api/v1/featured-projects/get-contract-data-funding-round',
	START_VESTING_FUNDING_ROUND = '/api/v1/featured-projects/start-vesting-funding-round',
	START_FUNDING_FUNDING_ROUND = '/api/v1/featured-projects/start-funding-funding-round',
	ADD_CONTRACT_TO_MARKETPLACE = '/api/v1/featured-projects/add-to-marketplace',
	WITHDRAW_ALL_TOKENS_FROM_VESTING_CONTRACT = '/api/v1/featured-projects/withdraw-all-tokens',
	WITHDRAW_RAISED_STABLE_COINS_FROM_FUNDING_CONTRACT = '/api/v1/featured-projects/withdraw-raised-stable-coins',
}
